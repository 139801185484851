import React from 'react'
import './main.css'
// Material
import Box from '@mui/material/Box';

const Main = ({ children, drawerWidth }) => {
    return (
        <Box
            component="main"
            className='main'
            sx={{
                flexGrow: 1,
                width: { md: `calc(100% - ${drawerWidth}px)` },
                overflowY: 'hidden',
            }}
        >
            {children}
        </Box>
    )
}

export default Main