import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import CryptoJS from 'crypto-js';
import { HeaderContext } from './helper/Context';
import { io } from "socket.io-client";
// Material
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import LinearProgress from '@mui/material/LinearProgress';
import ProfileMenu from './Components/ProfileMenu';
//Icons
import Logout from '@mui/icons-material/Logout';
// components
import Aside from './Components/Aside';
import Main from './Components/Main';
import Notification from './Components/Notification';
// Pages
const Dashboard = lazy(() => import('./Pages/Dashboard/Dashboard'))
const Article = lazy(() => import('./Pages/Article/Article'))
const ArticleAdd = lazy(() => import('./Pages/Article/ArticleAdd'))
const ErrandAdd = lazy(() => import('./Pages/Errand/ErrandAdd'))
const WorkerJobList = lazy(() => import('./Pages/Worker/WorkerJobList'))
const WorkerJobListView = lazy(() => import('./Pages/Worker/WorkerJobListView'))
const Enquiry = lazy(() => import('./Pages/Enquiry/Enquiry'))
const EnquiryView = lazy(() => import('./Pages/Enquiry/EnquiryView'))
const Quotation = lazy(() => import('./Pages/Quotation/Quotation'))
const QuotationView = lazy(() => import('./Pages/Quotation/QuotationView'))
const Job = lazy(() => import('./Pages/Job/Job'))
const JobView = lazy(() => import('./Pages/Job/JobView'))
const Plan = lazy(() => import('./Pages/Plan/Plan'))
const PlanView = lazy(() => import('./Pages/Plan/PlanView'))
const Account = lazy(() => import('./Pages/Account/Account'))
const AccountView = lazy(() => import('./Pages/Account/AccountView'))
const AccountAdd = lazy(() => import('./Pages/Account/AccountAdd'))
const Attribute = lazy(() => import('./Pages/Attribute/Attribute'))
const AttributeAdd = lazy(() => import('./Pages/Attribute/AttributeAdd'))
const PortAttribute = lazy(() => import('./Pages/Attribute/AttributeComponents/PortAttribute'))
const Warehouse = lazy(() => import('./Pages/Warehouse/Warehouse'))
const WarehouseAdd = lazy(() => import('./Pages/Warehouse/WarehouseAdd'))
const Invetory = lazy(() => import('./Pages/Warehouse/Inventory/Invetory'))
const InvetoryView = lazy(() => import('./Pages/Warehouse/Inventory/InvetoryView'))
const GDN = lazy(() => import('./Pages/Warehouse/Inventory/GDN'))
const GDNAdd = lazy(() => import('./Pages/Warehouse/Inventory/GDNAdd'))
const GDNView = lazy(() => import('./Pages/Warehouse/Inventory/GDNView'))
const GRNAdd = lazy(() => import('./Pages/Warehouse/Inventory/GRNAdd'))
const GRN = lazy(() => import('./Pages/Warehouse/Inventory/GRN'))
const GRNView = lazy(() => import('./Pages/Warehouse/Inventory/GRNView'))
const BOE = lazy(() => import('./Pages/Warehouse/Inventory/BOE'))
const BOEAdd = lazy(() => import('./Pages/Warehouse/Inventory/BOEAdd'))
const BOEView = lazy(() => import('./Pages/Warehouse/Inventory/BOEView'))
const Sku = lazy(() => import('./Pages/Warehouse/Sku/Sku'))
const SkuAdd = lazy(() => import('./Pages/Warehouse/Sku/SkuAdd'))
const StockMovement = lazy(() => import('./Pages/Warehouse/StockMovement/StockMovement'))
const Login = lazy(() => import('./Pages/Login/Login'))
const ResetPass = lazy(() => import('./Pages/Login/ResetPass'))
const NewPass = lazy(() => import('./Pages/Login/NewPass'))
const Verify = lazy(() => import('./Pages/Login/Verify'))
const PhoneVerify = lazy(() => import('./Pages/Login/PhoneVerify'))
const Contact = lazy(() => import('./Pages/Contact/Contact'))
const Shipment = lazy(() => import('./Pages/Shipment/Shipment'))
const Sender = lazy(() => import('./Pages/Sender/Sender'))
const AccountCustomerAdd = lazy(() => import('./Pages/Sender/AccountCustomerAdd'))
const SenderHandling = lazy(() => import('./Pages/Sender/SenderHandling'))
const SenderNewForm = lazy(() => import('./Pages/Sender/SenderNewForm'))

const App = () => {

    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const [socket, setSocket] = useState(null)
    const [userRole, setUserRole] = useState('')
    const [userID, setUserID] = useState('')

    const [headerTitle, setHeaderTitle] = useState('')
    const [headerLinkTo, setHeaderLinkTo] = useState('')

    const headerHeight = useState(0)

    const [asideStatus, setAsideStatus] = useState(true)
    const [notificationStatus, setNotificationStatus] = useState(true)

    const drawerWidth = 260;

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [windowHeight, setWindowHeight] = useState(0)


    const viewLessString = (string, length) => {
        let trimmedString = string.substring(0, length);
        if (string.length > trimmedString.length) {
            trimmedString = trimmedString + "..."
        }
        return trimmedString
    }

    function replaceWithBr(text) {
        return text.replace(/\n/g, "<br />")
    }


    const generateMD5Hash = (string) => {
        return CryptoJS.MD5(string).toString();
    }

    // Notification
    const [notifications, setNotifications] = useState([])

    // Detect User
    const cookies = new Cookies();
    const token = cookies.get('jwt')
    const [authorized, setAuthorized] = useState(false)


    // Config data

    useEffect(() => {
        setWindowHeight(window.innerHeight)

        if (token) {
            fetch(`${process.env.REACT_APP_SERVER_LINK}/api/login/verify`, {
                method: 'POST',
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: token
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.user_ID) {
                        setUserRole(data.role)
                        setUserID(data.user_ID)
                        setAuthorized(true)
                        localStorage.setItem("modules", JSON.stringify(data.access));
                        const key = process.env.REACT_APP_ACCESS_SECRET;
                        const user = data.user_ID.toString();
                        const hashedID = CryptoJS.AES.encrypt(user, key);
                        localStorage.setItem("u", JSON.stringify(hashedID.toString()));



                        // Get notification


                    } else {
                        setAuthorized(false)
                        navigate('/auth/login')
                    }
                    setIsLoading(true)
                });
        } else {
            setIsLoading(true)
            navigate('/auth/login')
        }


    }, [windowHeight, token, authorized])

    useEffect(() => {
        setSocket(io(`${process.env.REACT_APP_SERVER_LINK}`))
    }, [])
    useEffect(() => {
        // socket.on("connnection", () => {
        //     console.log("connected to server");
        // });
        // console.log(socket)
        if (socket) {
            socket.emit("newUser", userID, userRole)
            // console.log(socket.on("welcome", (msg) => {
            //     console.log(msg)
            // }))

            // socket.on("getNotification", data => {
            //     // setNot((prev) => [...prev, data])
            //     console.log(data)
            // })
        }

    }, [socket, userID])


    const logout = () => {
        navigate('/')
        cookies.remove('jwt');
        localStorage.clear();
        window.location.reload();
    }

    // const [notifications, setNotifications] = useState([
    //     {
    //         notification_title: "Congratulation Flora! 🎉",
    //         notification_content: "Won the monthly best seller badge",
    //         notification_date: "Today",
    //         notification_read: 0
    //     },
    //     {
    //         notification_title: "New user registered.",
    //         notification_content: "5 hours ago",
    //         notification_date: "Yesterday",
    //         notification_read: 0
    //     },
    //     {
    //         notification_title: "New message received 👋🏻",
    //         notification_content: "You have 10 unread messages",
    //         notification_date: "11 Aug",
    //         notification_read: 1
    //     },
    //     {
    //         notification_title: "Paypal",
    //         notification_content: "Received Payment",
    //         notification_date: "25 May",
    //         notification_read: 1
    //     },
    //     {
    //         notification_title: "Received Order 📦",
    //         notification_content: "New order received from John",
    //         notification_date: "19 Mar",
    //         notification_read: 1
    //     },
    //     {
    //         notification_title: "Finance report has been generated",
    //         notification_content: "25 hrs ago",
    //         notification_date: "27 Dec",
    //         notification_read: 1
    //     }
    // ])

    return (
        <HeaderContext.Provider value={{ headerLinkTo, setHeaderLinkTo, headerTitle, setHeaderTitle, asideStatus, setAsideStatus, notificationStatus, setNotificationStatus, viewLessString, replaceWithBr, windowHeight, headerHeight, generateMD5Hash, socket }}>
            {isLoading ?

                <Routes>
                    {authorized ?
                        <Route path='/' element={<LayOut userID={userID} socket={socket} drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} headerTitle={headerTitle} notifications={notifications} logout={logout} mobileOpen={mobileOpen} role={userRole} />}>
                            <Route path="/" element={<Dashboard userID={userID} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/dashboard" element={<Dashboard userID={userID} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/blog" element={<Article wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/blog/add" element={<ArticleAdd authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/errand/new" element={<ErrandAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/enquiry" element={<Enquiry wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/enquiry/:id" element={<EnquiryView socket={socket} userID={userID} wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/quotation" element={<Quotation wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/quotation/:id" element={<QuotationView socket={socket} userID={userID} wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/job" element={<Job wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/job/:id" element={<JobView userID={userID} wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/plan" element={<Plan wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/plan/:id" element={<PlanView wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/job/list" element={<WorkerJobList wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/job/list/:id" element={<WorkerJobListView wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>

                            <Route path="/attribute" element={<Attribute wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/attribute/add/:tableName" element={<AttributeAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>

                            <Route path="/attribute/add/ports" element={<PortAttribute wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse" element={<Warehouse wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/add" element={<WarehouseAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/sku" element={<Sku wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/sku/add" element={<SkuAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory" element={<Invetory wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/GDN" element={<GDN wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/GDN/add" element={<GDNAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/GDN/view/:id" element={<GDNView wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/GRN/add" element={<GRNAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/GRN" element={<GRN wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/GRN/view/:id" element={<GRNView wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/boe/add" element={<BOEAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/boe" element={<BOE wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/boe/view/:id" element={<BOEView wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/warehouse/Invetory/view" element={<InvetoryView wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} userID={userID} />} exact></Route>
                            <Route path="/warehouse/stockMovement" element={<StockMovement wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>

                            <Route path="/contact" element={<Contact wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/shipment" element={<Shipment wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                        
                            <Route path="/sender" element={<Sender wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/sender/process/:email/:phone" element={<SenderHandling wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/sender/customer/add" element={<AccountCustomerAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/sender/form/new" element={<SenderNewForm wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            
                            
                            
                            <Route path="/account" element={<Account wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/account/add" element={<AccountAdd wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            <Route path="/account/:id" element={<AccountView wh={windowHeight} hh={headerHeight} authorized={authorized} role={userRole} />} exact></Route>
                            {/* <Route path='/auth' element={<LoginLayOut />} /> */}
                        </Route>
                        :
                        <Route path='/auth' element={<LoginLayOut />}>
                            <Route path="/auth/login" element={<Login authorized={authorized} wh={windowHeight} />} exact></Route>
                            <Route path="/auth/reset-password" element={<ResetPass />} exact></Route>
                            <Route path="/auth/new-password/:vkey" element={<NewPass />} exact></Route>
                            <Route path="/auth/verify/:vkey" element={<Verify authorized={authorized} />} exact></Route>
                            {/* <Route path="/phone/verify/:vkey" element={<PhoneVerify authorized={authorized} />} exact></Route> */}
                        </Route>
                    }
                </Routes>


                : null}

        </HeaderContext.Provider >
    )
}

App.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const LayOut = ({ drawerWidth, handleDrawerToggle, headerTitle, notifications, logout, mobileOpen, role, socket, userID }) => {

    return (
        <Box>
            <Box className='app-box' sx={{ display: 'flex', boxSizing: 'border-box' }}  >

                <div className='header'>
                    <AppBar
                        position="fixed"
                        className='app-bar'
                        sx={{
                            width: { md: `calc(100% - ${drawerWidth}px)` },
                            ml: { md: `${drawerWidth}px` },
                            boxSizing: 'border-box',
                            background: 'none',
                        }}
                        elevation={0}
                    >

                        <Toolbar sx={{
                            backgroundColor: '#fff',
                            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                            transition: 'all 0.2s ease-in-out',
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{
                                            mr: 2,
                                            display: { md: 'none' },
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <h1 className='page-title'>{headerTitle}</h1>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Notification socket={socket} notifications={notifications} userID={userID} />
                                    <ProfileMenu />
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
                <Aside drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} role={role} />
                <Main drawerWidth={drawerWidth}>
                    <Suspense fallback={<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>}>
                        <Outlet />
                    </Suspense>
                </Main>
            </Box>

        </Box>
    )
}
const LoginLayOut = () => {
    return (
        <Suspense fallback={<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>}>
            <Outlet />
        </Suspense>
    )
}

export default App