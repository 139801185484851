import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
// Material 
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
// Colors
import { deepOrange, deepPurple } from '@mui/material/colors';
// Icons
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';


function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}


const Notification = ({ socket, userID }) => {

    const [notificationOpen, setNotificationOpen] = useState(false);
    const [transition, setTransition] = useState(undefined);
    const notificationClose = () => {
        setNotificationOpen(false);
    };
    const [notificationObj, setNotificationObj] = useState({})


    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    // const [menuLength, setMenuLength] = useState(notifications.length)
    const [menuLength, setMenuLength] = useState(0)
    const [unreadNotifications, setUnreadNotifications] = useState(0)
    const [newNotification, setNewNotification] = useState([])
    const [notifications, setNotifications] = useState([])
    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_LINK}/api/notification/${userID}`, {
            method: 'GET',
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setNotifications(data)
            });
        socket.on("getNotification", data => {
            setNewNotification((prev) => [...prev, data])
            setTransition(() => TransitionLeft);
            setNotificationOpen(true);
            setNotificationObj({ type: data.notiType, title: data.notiTitle, content: data.notiContent })
            setNotifications((prev) => [...prev, {
                link_to: data.notiLink,
                notification_ID: 27,
                notification_content: data.notiContent,
                notification_date: 0,
                notification_read: 0,
                notification_title: data.notiTitle,
                notification_type: data.notiType,
                userID: data.receiverUser,
                user_role: data.notiRole
            }])
        })
    }, [])

    useEffect(() => {
        setUnreadNotifications(notifications.filter(row => parseInt(row.notification_read) === 0).length)
    }, [notifications])

    const navigateTo = (to, notifiID, read) => {
        navigate(to)

        if (!read) {
            fetch(`${process.env.REACT_APP_SERVER_LINK}/api/notification/read`, {
                method: 'PUT',
                headers: {
                    'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: notifiID
                })
            })
                .then(response => response.json())
                .then(data => {
                    setNotifications(notifications => {
                        let newArr = [...notifications];
                        let index = newArr.findIndex(row => row.user_notification_ID === notifiID)
                        newArr[index].notification_read = 1
                        return newArr;
                    })
                });
        }

    }
    let vertical = 'bottom'
    let horizontal = 'right'
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Notifications">
                    <IconButton
                        onClick={handleClick}
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >

                        {unreadNotifications !== 0 ?
                            <Badge variant="dot" overlap="circular" color="primary" >
                                <NotificationsNoneOutlinedIcon />
                            </Badge>
                            :
                            <NotificationsNoneOutlinedIcon />
                        }

                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ width: '380px', display: 'flex', justifyContent: 'space-between', padding: '8px 16px', boxSizing: 'border-box', borderBottom: '1px solid rgba(58, 53, 65, 0.12)' }}>
                    <p>Notifications</p>
                    {unreadNotifications ? <Chip label={`${unreadNotifications} New`} color="primary" size="small" /> : null}
                </Box>

                <div style={{ height: '328px', overflowY: 'auto', minHeight: '328px' }}>
                    {notifications.map((val, i) => {
                        return (
                            <Card
                                onClick={() => navigateTo(val.link_to, val.user_notification_ID, val.notification_read)}
                                sx=
                                {
                                    i > 4 ?
                                        i + 1 === menuLength ?
                                            {
                                                width: 'calc(380px - 16px)',
                                                borderRadius: '0',
                                                boxShadow: 'none',
                                                marginRight: '-16px',
                                                boxSizing: 'border-box',

                                            }
                                            :
                                            {
                                                width: 'calc(380px - 16px)',
                                                borderRadius: '0',
                                                borderBottom: '1px solid rgba(58, 53, 65, 0.12)',
                                                boxShadow: 'none',
                                                marginRight: '-16px',
                                                boxSizing: 'border-box'
                                            }
                                        :
                                        {
                                            width: 'calc(380px)',
                                            borderRadius: '0',
                                            borderBottom: '1px solid rgba(58, 53, 65, 0.12)',
                                            boxShadow: 'none'
                                        }
                                }
                                key={i}
                            >
                                <CardActionArea sx={val.notification_read === 1 ? { borderRadius: '0' } : { borderRadius: '0', background: 'rgba(236, 237, 242, 0.5)' }}>
                                    <CardContent sx={{ padding: '0', display: 'flex', justifyContent: 'space-between', padding: '12px 16px', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', marginRight: '8px' }}>
                                            {val.notification_type === "order" ? <Avatar sx={{ bgcolor: deepOrange[500] }}><AssignmentOutlinedIcon /></Avatar> : <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>}
                                        </div>
                                        <div style={{ flex: '1', height: '100%' }}>
                                            <p style={{ color: 'rgba(58, 53, 65, 0.87)', fontWeight: 'bold', lineHeight: '16px', fontSize: '0.875rem', marginBottom: '8px' }}>{val.notification_title}</p>
                                            <p style={{ color: 'rgba(58, 53, 65, 0.6)', fontWeight: '400', fontSize: '0.875rem' }}>{val.notification_content}</p>
                                        </div>
                                        <div>
                                            <p style={{ fontWeight: '400', fontSize: '0.75rem', lineHeight: '1.66', color: 'rgba(58, 53, 65, 0.38)' }}>{val.notification_date === 0 ? 'now' : moment(val.notification_date).calendar()}</p>
                                        </div>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        )
                    })}
                </div>
                <Box sx={{ width: '380px', display: 'flex', justifyContent: 'space-between', padding: '8px 16px', boxSizing: 'border-box', borderBottom: '1px solid rgba(58, 53, 65, 0.12)', borderTop: '1px solid rgba(58, 53, 65, 0.12)' }}>
                    <Button variant="contained" fullWidth>Read All Notifications</Button>
                </Box>
            </Menu>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={notificationOpen}
                onClose={notificationClose}
                TransitionComponent={transition}
                key={transition ? transition.name : ''}
            >
                <CardContent sx={
                    {
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '12px 16px !important',
                        alignItems: 'center',
                        background: '#fff',
                        width: '300px',
                        borderRadius: '4px',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    }
                }>
                    <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', marginRight: '8px', height: '100%' }}>
                        {notificationObj.type === "order" ? <Avatar sx={{ bgcolor: deepOrange[500] }}><AssignmentOutlinedIcon /></Avatar> : <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>}

                    </div>
                    <div style={{ flex: '1', height: '100%' }}>
                        <p style={{ color: 'rgba(58, 53, 65, 0.87)', fontWeight: 'bold', lineHeight: '16px', fontSize: '0.875rem', marginBottom: '8px' }}>{notificationObj.title}</p>
                        <p style={{ color: 'rgba(58, 53, 65, 0.6)', fontWeight: '400', fontSize: '0.875rem' }}>{notificationObj.content}</p>
                    </div>
                </CardContent>
            </Snackbar>
        </Box>
    )
}

export default Notification