import React, { useState, useEffect } from 'react'
import './aside.css'
import { useNavigate, Link } from "react-router-dom";
import logo from '../Assets/logo-black.png'
// Material
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// Icons
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TuneIcon from '@mui/icons-material/Tune';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

import GradingIcon from '@mui/icons-material/Grading';

import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
const Aside = ({ drawerWidth, mobileOpen, handleDrawerToggle, role }) => {

    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [collapseIndex, setCollapseIndex] = useState(0)
    // Collapse menu
    const [customerServiceOpen, setCustomerServiceOpen] = useState(false);
    const [opperationOpen, setOpperationOpen] = useState(false);

    const handleListCollapseItem = (event, index) => {
        setCollapseIndex(index)
        if (index === 200) {
            if (customerServiceOpen === true) {
                setCollapseIndex(0)
            }
            setCustomerServiceOpen(!customerServiceOpen)
        } else if (index === 300) {
            if (opperationOpen === true) {
                setCollapseIndex(1)
            }
            setOpperationOpen(!opperationOpen)
        }
    }

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setCollapseIndex(0)
        if (index === 0) {
        } else if (index === 1) {
        } else if (index === 201) {
            setCollapseIndex(200)
            setCustomerServiceOpen(true)
        } else if (index === 202) {
            setCollapseIndex(200)
            setCustomerServiceOpen(true)
        } else if (index === 301) {
            setCollapseIndex(300)
            setOpperationOpen(true)
        } else if (index === 302) {
            setCollapseIndex(300)
            setOpperationOpen(true)
        } else if (index === 3) {
        } else if (index === 4) {
        } else if (index === 6) {
        } else if (index === 7) {
        }
    };
    const [access, setAccess] = useState([])

    // Menu selection
    const [menu, setMenu] = useState(1);
    const changeMenu = (event) => {
        setMenu(event.target.value);
    };






    useEffect(() => {

        if (window.location.pathname.indexOf("/errand") !== -1) {
            setSelectedIndex(2)
        } else if (window.location.pathname.indexOf("/blog") !== -1) {
            setSelectedIndex(1)
        } else if (window.location.pathname.indexOf("/enquiry") !== -1) {
            setCollapseIndex(200)
            setCustomerServiceOpen(true)
            setSelectedIndex(201)
        } else if (window.location.pathname.indexOf("/quotation") !== -1) {
            setCollapseIndex(200)
            setCustomerServiceOpen(true)
            setSelectedIndex(202)
        } else if (window.location.pathname.indexOf("/job") !== -1) {
            setCollapseIndex(300)
            setCustomerServiceOpen(true)
            setSelectedIndex(301)
        } else if (window.location.pathname.indexOf("/plan") !== -1) {
            setCollapseIndex(300)
            setCustomerServiceOpen(true)
            setSelectedIndex(302)
        } else if (window.location.pathname.indexOf("/account") !== -1) {
            setSelectedIndex(3)
        } else if (window.location.pathname.indexOf("/job/list") !== -1) {
            setSelectedIndex(4)
        } else if (window.location.pathname.indexOf("/attribute") !== -1) {
            setSelectedIndex(6)
        } else if (window.location.pathname.indexOf("/warehouse") !== -1) {
            setSelectedIndex(7)
        }else if (window.location.pathname.indexOf("/sender") !== -1) {
            setSelectedIndex(10)
        }
        else {
            setSelectedIndex(0)
        }

        if (localStorage.getItem("modules") !== null) {
            let module = JSON.parse(localStorage.getItem("modules"))
            for (let i = 0; i < module.length; i++) {
                setAccess(access => [...access, parseInt(module[i].code)])
            }
            setIsLoading(true)
            return
        }
    }, [])



    const menuRender = () => {
        if (menu === 1) {
            return (
                <List component="nav">
                    <Link to="/">
                        <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0)}
                        >
                            <ListItemIcon>
                                {selectedIndex === 0 ? <HomeIcon /> : <HomeOutlinedIcon />}
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </Link>
                    {access.includes(2) ?
                        <Link to="/blog">
                            <ListItemButton
                                selected={selectedIndex === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <ListItemIcon>
                                    {selectedIndex === 1 ? <DescriptionIcon /> : <DescriptionOutlinedIcon />}
                                </ListItemIcon>
                                <ListItemText primary="Blog" />
                            </ListItemButton>
                        </Link>
                        : null}
                    {/* {access.includes(3) ?
                        <ListItemButton
                            selected={selectedIndex === 2}
                            onClick={(event) => handleListItemClick(event, 2)}
                        >
                            <ListItemIcon>
                                {selectedIndex === 2 ? <AssignmentIcon /> : <AssignmentOutlinedIcon />}
                            </ListItemIcon>
                            <ListItemText primary="Job" />
                        </ListItemButton>
                        : null} */}

                    {access.includes(31) || access.includes(32) ?
                        <ListItemButton
                            selected={collapseIndex === 200}
                            onClick={(event) => handleListCollapseItem(event, 200)}
                            className="list-collapse"
                        >
                            <ListItemIcon>
                                {collapseIndex === 200 ? <AssignmentIcon /> : <AssignmentOutlinedIcon />}
                            </ListItemIcon>
                            <ListItemText primary="Customer service" />
                            {customerServiceOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        : null}


                    <Collapse in={customerServiceOpen} timeout="auto" unmountOnExit>
                        <List component="div" >
                            {access.includes(31) ?
                                <Link to="/enquiry">
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        selected={selectedIndex === 201}
                                        onClick={(event) => handleListItemClick(event, 201)}
                                    >
                                        <ListItemIcon>
                                            {selectedIndex === 201 ? <MarkUnreadChatAltIcon /> : <MarkUnreadChatAltOutlinedIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary="Enquiry" />
                                    </ListItemButton>
                                </Link>
                                : null}

                            {access.includes(32) ?
                                <Link to="/quotation">
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        selected={selectedIndex === 202}
                                        onClick={(event) => handleListItemClick(event, 202)}
                                    >
                                        <ListItemIcon>
                                            {selectedIndex === 202 ? <QuestionAnswerIcon /> : <QuestionAnswerOutlinedIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary="Quotataion" />
                                    </ListItemButton>
                                </Link>
                                : null}
                        </List>

                    </Collapse>


                    {access.includes(34) || access.includes(35) ?
                        <ListItemButton
                            selected={collapseIndex === 300}
                            onClick={(event) => handleListCollapseItem(event, 300)}
                            className="list-collapse"
                        >
                            <ListItemIcon>
                                {collapseIndex === 300 ? <AssignmentIcon /> : <AssignmentOutlinedIcon />}
                            </ListItemIcon>
                            <ListItemText primary="Opperation" />
                            {opperationOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        : null}

                    <Collapse in={opperationOpen} timeout="auto" unmountOnExit>
                        <List component="div" >
                            {access.includes(35) ?
                                <Link to="/job">
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        selected={selectedIndex === 301}
                                        onClick={(event) => handleListItemClick(event, 301)}
                                    >
                                        <ListItemIcon>
                                            {selectedIndex === 301 ? <WorkIcon /> : <WorkOutlineOutlinedIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary="Job" />
                                    </ListItemButton>
                                </Link>
                                : null}
                            {access.includes(34) ?
                                <Link to="/plan">
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        selected={selectedIndex === 302}
                                        onClick={(event) => handleListItemClick(event, 302)}
                                    >
                                        <ListItemIcon>
                                            <GradingIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Plan" />
                                    </ListItemButton>
                                </Link>
                                : null}
                        </List>

                    </Collapse>



                    {access.includes(4) ?
                        <Link to="/account">
                            <ListItemButton
                                selected={selectedIndex === 3}
                                onClick={(event) => handleListItemClick(event, 3)}
                            >
                                <ListItemIcon>
                                    {selectedIndex === 3 ? <PersonIcon /> : <PersonOutlineOutlinedIcon />}
                                </ListItemIcon>
                                <ListItemText primary="Accounts" />
                            </ListItemButton>
                        </Link>
                        : null}


                    {access.includes(7) ?
                        <Link to="/job/list">
                            <ListItemButton
                                selected={selectedIndex === 4}
                                onClick={(event) => handleListItemClick(event, 4)}
                            >
                                <ListItemIcon>
                                    {selectedIndex === 4 ? <WorkIcon /> : <WorkOutlineIcon />}

                                </ListItemIcon>
                                <ListItemText primary="Job" />
                            </ListItemButton>
                        </Link>
                        : null}

                    {access.includes(5) ?
                        <ListItemButton
                            selected={selectedIndex === 5}
                            onClick={(event) => handleListItemClick(event, 5)}
                        >
                            <ListItemIcon>
                                {selectedIndex === 5 ? <AssessmentIcon /> : <AssessmentOutlinedIcon />}
                            </ListItemIcon>
                            <ListItemText primary="Report" />
                        </ListItemButton>
                        : null}

                    {access.includes(6) ?
                        <Link to="/attribute">
                            <ListItemButton
                                selected={selectedIndex === 6}
                                onClick={(event) => handleListItemClick(event, 6)}
                            >
                                <ListItemIcon>
                                    <TuneIcon />
                                </ListItemIcon>
                                <ListItemText primary="Attribute" />
                            </ListItemButton>
                        </Link>
                        : null}

                    {access.includes(8) ?
                        <Link to="/warehouse">
                            <ListItemButton
                                selected={selectedIndex === 7}
                                onClick={(event) => handleListItemClick(event, 7)}
                            >
                                <ListItemIcon>
                                    {selectedIndex === 7 ? <WarehouseIcon /> : <WarehouseOutlinedIcon />}
                                </ListItemIcon>
                                <ListItemText primary={role === 4 ? "My warehouse" : "Warehouse"} />
                            </ListItemButton>
                        </Link>
                        : null}
                    {role === 4 ?
                        <Link to="/shipment">
                            <ListItemButton
                                selected={selectedIndex === 9}
                                onClick={(event) => handleListItemClick(event, 9)}
                            >
                                <ListItemIcon>
                                    {selectedIndex === 9 ? <LocalShippingIcon /> : <LocalShippingOutlinedIcon />}
                                </ListItemIcon>
                                <ListItemText primary="My shipment" />
                            </ListItemButton>
                        </Link>
                        : null}
                    {role === 4 ?
                        <Link to="/contact">
                            <ListItemButton
                                selected={selectedIndex === 8}
                                onClick={(event) => handleListItemClick(event, 8)}
                            >
                                <ListItemIcon>
                                    <ConnectWithoutContactIcon />
                                </ListItemIcon>
                                <ListItemText primary="Contact us" />
                            </ListItemButton>
                        </Link>
                        : null}

                    {access.includes(9) ?
                        <Link to="/sender">
                            <ListItemButton
                                selected={selectedIndex === 10}
                                onClick={(event) => handleListItemClick(event, 10)}
                            >
                                <ListItemIcon>
                                    <ForwardToInboxIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sender" />
                            </ListItemButton>
                        </Link>
                        : null}
                </List>
            )
        } else if (menu === 2) {
            return (
                ['Dashbord'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))
            )

        } else if (menu === 3) {
            return (
                ['Setting'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}

                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))
            )
        }
    }



    const drawer = (
        <div className='aside-menu'>
            <Toolbar>
                <img src={logo} alt="logo" />
            </Toolbar>
            <Divider />
            {role === 64 ?
                <div className='aside-select-menu'>
                    <FormControl
                        fullWidth
                        sx={{
                            '& .MuiFormLabel-root': {
                                display: 'none'
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0)'
                            },
                            '& .MuiInputBase-root': {
                                color: '#333'
                            },
                            '& .MuiInputBase-root svg ': {
                                fill: '#333'
                            }
                        }}
                    >
                        <InputLabel>Menu</InputLabel>
                        <Select
                            value={menu}
                            label="Menu"
                            onChange={changeMenu}
                        >
                            <MenuItem value={1}>Zaxon CRM</MenuItem>
                            <MenuItem value={2}>Website</MenuItem>
                            <MenuItem value={3}>Admin settings</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                : null}
            {menuRender()}
        </div>
    );

    return (
        <div className='aside' >
            <Box
                component="nav"

                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >

                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiPaper-root': {
                            borderRight: 0
                        },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: 'rgb(244, 245, 250)',
                        },
                        '& .MuiList-root': {
                            paddingRight: '16px',
                        },
                        '& .MuiListItemButton-root': {
                            marginBottom: '8px',
                            borderTopRightRadius: '50px',
                            borderBottomRightRadius: '50px',
                        },
                        '& .Mui-selected .MuiTypography-root': {
                            color: '#ffffff'
                        },
                        '& .MuiListItemIcon-root svg': {
                            fill: '#524E5A'
                        },
                        '& .Mui-selected .MuiListItemIcon-root svg': {
                            fill: '#FFFFFF'
                        },
                        '& .MuiButtonBase-root': {
                            backgroundColor: 'none',
                            transition: 'all 0.3s ease-in-out',
                            height: '42px',
                        },
                        '& .MuiButtonBase-root.Mui-selected': {
                            backgroundImage: 'linear-gradient(98deg, #f5b05c, var(--zaxonOrange) 94%)',
                            boxShadow: 'rgb(58 53 65 / 42%) 0px 4px 8px -4px'
                        },
                        '& .MuiButtonBase-root.Mui-selected.list-collapse': {
                            background: 'rgba(58, 53, 65, 0.04)',
                            boxShadow: 'rgb(58 53 65 / 42%) 0px 4px 8px -4px'
                        }
                    }}
                >
                    {drawer}
                </Drawer>

                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: 'rgb(244, 245, 250)'
                        },
                        '& .MuiList-root': {
                            paddingRight: '16px'
                        },
                        '& .MuiListItemButton-root': {
                            marginBottom: '8px',
                            borderTopRightRadius: '50px',
                            borderBottomRightRadius: '50px',
                            color: '#524E5A'
                        },
                        '& .Mui-selected .MuiTypography-root': {
                            color: '#ffffff'
                        },
                        '& .Mui-selected.list-collapse .MuiTypography-root': {
                            color: '#524E5A'
                        },
                        '& .MuiListItemIcon-root svg': {
                            fill: '#524E5A'
                        },

                        '& .Mui-selected .MuiListItemIcon-root svg': {
                            fill: '#FFFFFF'
                        },
                        '& .Mui-selected.list-collapse .MuiListItemIcon-root svg': {
                            fill: '#524E5A'
                        },
                        '& .MuiButtonBase-root': {
                            backgroundColor: 'none',
                            transition: 'all 0.3s ease-in-out',
                            height: '42px',
                        },
                        '& .MuiButtonBase-root.Mui-selected': {
                            backgroundImage: 'linear-gradient(98deg, #f5b05c, var(--zaxonOrange) 94%)',
                            boxShadow: 'rgb(58 53 65 / 42%) 0px 4px 8px -4px'
                        },
                        '& .MuiButtonBase-root.Mui-selected.list-collapse': {
                            background: 'rgba(58, 53, 65, 0.04)',
                            boxShadow: 'none'
                        }
                    }}
                    open
                >
                    {drawer}
                </Drawer>

            </Box>
        </div >
    )
}

export default Aside